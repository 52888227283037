import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide53/dataPageGuide53"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        10 ejemplos de recordatorios de pago por SMS y WhatsApp 
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/recordatorios-pago/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Facilita tus finanzas con recordatorios automáticos de pago vía SMS y WhatsApp. Mantén el control y nunca olvides una fecha límite."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="10 ejemplos de recordatorios de pago por SMS y WhatsApp "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/recordatorios-pago/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Facilita tus finanzas con recordatorios automáticos de pago vía SMS y WhatsApp. Mantén el control y nunca olvides una fecha límite."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Cobranza "
      location={location}
      typeGuide="guide53"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
